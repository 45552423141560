<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Kontakt</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
<!--        <div class="col-sm-offset-1 col-sm-6">-->
<!--          <form-->
<!--            id="contact-form"-->
<!--            name="contact-form"-->
<!--            method="post"-->
<!--            novalidate="novalidate"-->
<!--            @submit.prevent="checkForm"-->
<!--          >-->
<!--            <div-->
<!--              v-if="errors.length"-->
<!--              class="error"-->
<!--            >-->
<!--              <b>Wystąpił błąd:</b>-->
<!--              <ul>-->
<!--                <li-->
<!--                  v-for="(error, index) in errors"-->
<!--                  :key="index"-->
<!--                >-->
<!--                  {{ error }}-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--            <fieldset>-->
<!--              <div-->
<!--                id="alert-area"-->
<!--                ref="alert-area"-->
<!--              />-->

<!--              <input-->
<!--                v-model="name"-->
<!--                class="col-xs-12"-->
<!--                type="text"-->
<!--                name="name"-->
<!--                placeholder="imię / nazwisko"-->
<!--              >-->

<!--              <input-->
<!--                v-model="email"-->
<!--                class="col-xs-12"-->
<!--                type="text"-->
<!--                name="email"-->
<!--                placeholder="email"-->
<!--              >-->

<!--              <input-->
<!--                v-model="subject"-->
<!--                class="col-xs-12"-->
<!--                type="text"-->
<!--                name="subject"-->
<!--                placeholder="temat"-->
<!--              >-->

<!--              <textarea-->
<!--                v-model="message"-->
<!--                class="col-xs-12"-->
<!--                name="message"-->
<!--                rows="8"-->
<!--                cols="25"-->
<!--                placeholder="wiadomość"-->
<!--              />-->
<!--            </fieldset>-->
<!--            &lt;!&ndash;<fieldset>&ndash;&gt;-->
<!--            &lt;!&ndash;<recaptcha&ndash;&gt;-->
<!--            &lt;!&ndash;@error="onError"&ndash;&gt;-->
<!--            &lt;!&ndash;@success="onSuccess"&ndash;&gt;-->
<!--            &lt;!&ndash;@expired="onExpired"&ndash;&gt;-->
<!--            &lt;!&ndash;/>&ndash;&gt;-->
<!--            &lt;!&ndash;</fieldset>&ndash;&gt;-->
<!--            <fieldset>-->
<!--              <input id="submit" class="btn btn-default" type="submit" name="submit" value="Wyślij">-->
<!--            </fieldset>-->
<!--          </form>-->
<!--        </div>-->
        <div class="col-sm-5">
          <div class="widget widget-contact">
            <h3 class="widget-title">
              Dane kontaktowe:
            </h3>

            <ul>
              <li>
                <span>ul.Główna 46</span>
                61-007 Poznań<br>
                tel: 601-757-446<br>
                email: <a href="mailto:biuro@biurostorno.pl">biuro@biurostorno.pl</a>
              </li>
              <li>
                <span>os. Dębina 2</span>
                61-450 Poznań<br>
                tel:  507-165-633<br>
                email: <a href="mailto:storno@biurostorno.pl">storno@biurostorno.pl</a><br>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iframe-rwd">
            <iframe
              widt="100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.184533896025!2d16.971388000000005!3d52.42145400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b7c51447a29%3A0x4460cd666608048e!2sBiuro+Rachunkowe+Pozna%C5%84+%22STORNO%22+Jacek+Bartczak!5e0!3m2!1spl!2spl!4v1430677235638"
              width="600"
              height="450"
              frameborder="0"
              style="border:0"
            />
          </div>
          <div class="iframe-rwd">
            <iframe
              widt="100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.4516951435667!2d16.90281895172528!3d52.3622291796855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045abc09b1f221%3A0x67b6ca7bc0f456cb!2sBiuro+Rachunkowe+Pozna%C5%84+%22STORNO%22+Jacek+Bartczak!5e0!3m2!1spl!2spl!4v1489225696105"
              width="600"
              height="450"
              frameborder="0"
              style="border:0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
export default {
  head: {
    title: 'Kontakt - Biuro rachunkowe Poznań "STORNO"'
  },
  data () {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      errors: []
    }
  },
  methods: {
    checkForm (e) {
      e.preventDefault()

      this.errors = []
      if (!this.name) {
        this.errors.push('Proszę uzupełnić imię / nazwisko')
      }
      if (!this.email) {
        this.errors.push('Proszę uzupełnić email')
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Proszę wprowadzić poprawny adres email')
      }
      if (!this.subject) {
        this.errors.push('Proszę wpisać temat!')
      }
      if (!this.message) {
        this.errors.push('Proszę wpisać wiadomość!')
      }
      if (!this.errors.length) {
        this.sendMail()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    sendMail () {
      axios({
        method: 'post',
        url: '/api/contact',
        data: {
          'name': this.name,
          'email': this.email,
          'subject': this.subject,
          'message': this.message
        }
      })
        .then((response) => {
          this.errors = []

          if (response && response.data.message === 'OK') {
            this.$refs['alert-area'].innerHTML = '<div class="alert alert-success">Twoja wiadomość została wysłana!</div>'
            this.name = ''
            this.email = ''
            this.subject = ''
            this.message = ''
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
        .catch((e, response) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          if (e.response.status === 422) {
            this.errors.push('Wystąpił błąd. Proszę sprawdzić czy dane zostały prawidłowo uzupełnione.')
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
    }
  }
}
</script>
